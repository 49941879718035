<template>
  <div align="center">
    <div><v-icon x-large color="red">mdi-close-circle</v-icon></div>
    <h1 :style="styleTitle">Commande abandonnée</h1>
    <br />
    <div>
      <v-row class="pt-1">
        <v-col>
          <div class="text" align="center">
            Vous avez abandonné le paiement. vous pouvez à tout moment revenir
            en scannant le qrCode fourni.
          </div>
        </v-col>
      </v-row>
    </div>
    <br />
    <br />

    <v-img :src="icon" :width="iconWidth" class="mx-auto mb-5"></v-img>
    <h1 :style="styleTitle">A très bientôt.</h1>
  </div>
</template>

<script>
import { localStorageService } from '@/services/localStorage.service'
export default {
  name: 'PaymentCancel',
  data() {
    return {
      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
    }
  },
  mounted() {},
}
</script>

<style scoped>
center {
  margin: 15vw;
}

h1 {
  color: var(--border);
  font-size: 2em;
}
</style>
